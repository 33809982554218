@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: Ubuntu;
      font-weight: 400;
      src: url(./fonts/Ubuntu-Regular.ttf) format("truetype");
    }
    @font-face {
      font-family: Ubuntu;
      font-weight: 700;
      src: url(./fonts/Ubuntu-Bold.ttf) format("truetype");
    }
    @font-face {
      font-family: Raleway;
      src: url(./fonts/Raleway-VariableFont_wght.ttf) format("truetype-variations");
      font-weight: 100 900;
    }
}

@layer utilities {
  .a-style {
    @apply font-sans font-medium text-indigo-800 hover:text-indigo-600;
  }
  .billing-credit-card-box {
    border: 1px #000 solid;
    border-radius: 0.5rem;
    height: 8rem;
    width: 14rem;
    background-color: #272727;
    color: white;
  }
  
  .billing-credit-card-brand {
    position: relative;
    left: 1rem;
    top: 0.5rem;
    font-weight: 800;
  }
  
  .billing-credit-card-last4-label {
    position: relative;
    font-size: 0.75rem;
    left: 8rem;
    top: 0rem;
  }
  
  .billing-credit-card-last4 {
    position: relative;
    left: 8rem;
    top: 0rem;
  }
  
  .billing-credit-card-exp-label {
    position: relative;
    font-size: 0.75rem;
    left: 1rem;
    bottom: 1.2rem;
  }
  
  .billing-credit-card-exp {
    position: relative;
    left: 1rem;
    bottom: 1.2rem;
  }
  
  .billing-creditcard-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}